import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function BasicCard(props) {
  return (
    <Card sx={{ minWidth: 100, m: 0.5}}>
      <CardContent sx={{fontFamily: 'Poppins'}}>
        <Typography sx={{ fontSize: 14, fontFamily: 'Poppins' }} color="text.secondary" gutterBottom>
          Round {props.roundNumber}
        </Typography>
        <Typography sx={{fontFamily: 'Poppins'}} variant="h5" component="div">
         {props.date}
        </Typography>
        <Typography sx={{fontFamily: 'Poppins'}} variant="h4" component="div">
         {props.eventTime}
        </Typography>
        <Typography sx={{ mb: 1.5, fontFamily: 'Poppins'}} color="text.secondary">
         {props.timeControl}
        </Typography>
      </CardContent>
    </Card>
  );
}