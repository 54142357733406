import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as MUILink } from '@mui/material';
import '../styles.css'

export default function EventType(props) {
  return (
    <Card sx={{ minHeight: 275, maxWidth: 345, m: 2 }}>
      <CardContent>
        <Typography gutterBottom variant="h4" component="div" fontFamily='Poppins'>
          {props.title}
        </Typography>
        <Typography variant="h5" fontFamily='Poppins'>
          {props.entryFee}
        </Typography>
        <Typography variant="h5" fontFamily='Poppins'>
          {props.content}
        </Typography>
      </CardContent>
      <div className={"flex-grow"} />
      <CardActions className={"parentFlexSplit"}>
        <Button component={MUILink} target="_blank" href={props.registerLink}
          size="medium" variant="contained" sx={{ fontFamily: 'Poppins' }}>
            Register</Button>

        <Button component={MUILink} target="_blank" href={props.advEntriesLink}
          size="medium" variant="contained" sx={{ fontFamily: 'Poppins' }}>
            Adv Entries</Button>
      </CardActions>
    </Card>
  );
}
// {/* , position: 'absolute', bottom: -60 */}