import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/chess-clock.jpg"
import BasicCard from "../components/ScheduleCard"
import { scheduleItems } from "../components/ScheduleItems"
import { Typography } from "@mui/material";
import  Footer from "../components/Footer";
import "../styles.css"

function ScheduleRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={AboutImg}
            title="Schedule"
            btnClass="hide"
        ></Hero>
        <br></br>
        <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Scholastic Tournament: 9:00 AM, next rounds as soon as all games in a section finish. All rounds G/30;d5.
        </Typography>
        <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Blitz Tournament: First round at 8:30 PM, subsequent rounds occur as soon as possible.<br/><br/>
        </Typography>
        <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins'}}>
            Blue Ridge Open - Schedules
        </Typography>
        <div className="scheduleTimes">
            { scheduleItems.map((item) => {
                return (
                    <>
                    <h2>{item.schedule}</h2>
                    <div className="flexbox-container">
                        { item.rounds.map((item2, index) => {
                            return (
                            <BasicCard
                                roundNumber={index + 1}
                                date={item2.date}
                                eventTime={item2.time}
                                timeControl={item2.timeControl}
                            ></BasicCard>)
                        })}
                    </div>
                    </>
                )
            })}
        </div>
        <Footer></Footer>
        </>
    )
}

export default ScheduleRoute;