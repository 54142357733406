export const scheduleItems = [
    {
        schedule: "3-Day Schedule",
        rounds: [
            {
                date: "September 20",
                time: "7 PM",
                timeControl: "G/90+30"
            },
            {
                date: "September 21",
                time: "10 AM",
                timeControl: "G/90+30"
            },
            {
                date: "September 21",
                time: "4 PM",
                timeControl: "G/90+30"
            },
            {
                date: "September 22",
                time: "9 AM",
                timeControl: "G/90+30"
            },
            {
                date: "September 22",
                time: "3 PM",
                timeControl: "G/90+30"
            }
        ]
    },
    {
        schedule: "2-Day Schedule (only an option for U1900, U1600, U1300)",
        rounds: [
            {
                date: "September 21",
                time: "10 AM",
                timeControl: "G/60;d5"
            },
            {
                date: "September 21",
                time: "1 PM",
                timeControl: "G/60;d5"
            },
            {
                date: "September 21",
                time: "4 PM",
                timeControl: "G/90+30"
            },
            {
                date: "September 22",
                time: "9 AM",
                timeControl: "G/90+30"
            },
            {
                date: "September 21",
                time: "3 PM",
                timeControl: "G/90+30"
            }
        ]
    }
]