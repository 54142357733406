import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ContactImg from "../assets/home-flag.jpg"
import Footer from "../components/Footer";
import { Typography } from '@mui/material';
import "../styles.css"

function ContactRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={ContactImg}
            title="Contact"
            btnClass="hide"
        ></Hero>
        <br></br>
        <div className="contact-route-div">
            <Typography variant="h4" component="div" fontFamily='Poppins'>
                General Inquiries</Typography>

            <Typography variant="h5" component="div" fontFamily='Poppins'>
                <i className="fa-solid fa-envelope"></i> <nbsp/>
                director@atlanticchessassociation.com
            </Typography>
            
            
            <Typography variant="h5" component="div" fontFamily='Poppins'>
                <i className="fa-solid fa-phone"></i> <nbsp/>
                (703) 975 6032
            </Typography>
            <br></br>
        </div>
        <Footer></Footer>
        </>
    )
}

export default ContactRoute;