import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import ResultsImg from "../assets/my-profit-tutor-5VH8oW-hnvk-unsplash.jpg"
import Footer from "../components/Footer";
// import NormPlayersTable from "../components/NormTable";
import { Link } from "react-router-dom";

import "../styles.css"

// const gm_players = [
//     { name: 'GM Sergey Erenburg', country: 'USA', fide: 2524},
//     { name: 'GM Vojtech Plat', country: 'CZE', fide: 2488 },
//     { name: 'GM Oliver Barbosa', country: 'PHI', fide: 2482 },
//     { name: 'GM Gabor Nagy', country: 'HUN', fide: 2448 },
//     { name: 'IM Johnathan Han', country: 'CAN', fide: 2357 },
//     { name: 'FM Gauri Shankar', country: 'IND', fide: 2335 },
//     { name: 'IM Sahil Sinha', country: 'USA', fide: 2321 },
//     { name: 'FM Lucius Melillo', country: 'USA', fide: 2306 },
//     { name: 'IM Nicholas Vettese', country: 'CAN', fide: 2276 },
//     { name: 'FM Aziz Degenbaev', country: 'KGZ', fide: 2252 }
//   ];

function InvitationalRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={ResultsImg}
            title="Invitational Tournaments"
            btnClass="hide"
        ></Hero>
        <br></br>
        <div className="details">
            <h1>Norm Tournaments</h1>
            <h1>Previous Tournaments: <Link to={"https://www.chess.com/blog/Learnedmachine/fm-gauri-shankar-scores-5-5-9-to-get-an-im-norm-at-gm-norm-invitational"}>Article</Link></h1>
            <h1>All Games: <Link to={"https://lichess.org/broadcast/atlantic-chess-independence-day-gm-norm-invitational/round-1/LY3n4OkX#boards"}>lichess</Link></h1>
            
            {/* <h2>Roster</h2>
            <NormPlayersTable players={gm_players} /> */}
        </div>
        <br></br>
        <div>
        {/* <h2>Chief Arbiter: IA Gregory Vaserstein</h2>
        <h2>Venue: Hampton Inn & Suites Washington-Dulles International Airport (4050 Westfax Dr., Chantilly, VA 20151)</h2> */}
        </div>
        <br></br>
        {/* <div className="timings">
            <h1>Round Timings</h1>
            <h3>Saturday, June 29 - Round 1 (5 PM)</h3>
            <h3>Sunday, June 30 - Round 2 (10 AM), Round 3 (5 PM)</h3>
            <h3>Monday, July 1 - Round 4 (10 AM), Round 5 (5 PM)</h3>
            <h3>Tuesday, July 2 - Round 6 (10 AM)</h3>
            <h3>Wednesday, July 3 - Round 7 (10 AM), Round 8 (5 PM) </h3>
            <h3>Thursday, July 4 - Round 9 (9 AM)</h3>
        </div> */}
        <br></br>
        <Footer></Footer>
        </>
    )
}

export default InvitationalRoute;