export const navBarItems = [
    {
        title: "Home",
        url: "/",
        className: "nav-links",
        icon: "fa-solid fa-house"
    },
    {
        title: "Registration",
        url: "/registration",
        className: "nav-links",
        icon: "fa-solid fa-address-card"
    },
    {
        title: "Schedule",
        url: "/schedule",
        className: "nav-links",
        icon: "fa-regular fa-calendar-days"
    },
    {
        title: "Invitational",
        url: "/invitational",
        className: "nav-links",
        icon: "fa-solid fa-trophy"
    },
    {
        title: "Contact",
        url: "/contact",
        className: "nav-links",
        icon: "fa-solid fa-address-book"
    }
]