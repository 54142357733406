import { Component } from "react";
import { navBarItems } from "./NavbarItems"
import { Link } from "react-router-dom";
import "./Navbar.css"

class Navbar extends Component
{
    state = { clicked: false };
    handleClick = () => {
        this.setState({clicked: !this.state.clicked})
    };

    render() 
    {
        return(
            <nav className="NavbarItems">
                <h1 className="navbar-logo">Atlantic Chess Association</h1>

                <div className="menu-icons" onClick={this.handleClick}>
                    <i className={this.state.clicked ? "fas fa-times" : "fas fa-bars"}></i>
                </div>

                <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                { navBarItems.map((item, index) => {
                    return(
                        <li key={index}>
                            <Link className = {item.className} to={item.url}>
                                <i className={item.icon}></i>{item.title}
                            </Link>
                        </li>
                    )
                })}
                </ul>
            </nav>
        )
    }
}

export default Navbar;