import "./Hero.css";

function Hero(props) {
    return (
        <>
        <div className={props.className}>
            <img alt="heroImg" src={props.heroImg}>
            </img>
            <div className="hero-text">
                <h1>{props.title}</h1>
                <h1>{props.title2}</h1>
                <h4>{props.prizeFund}</h4>
                <div className="textInfo">
                    <h4>{props.text}</h4>
                    <h3>{props.text2}</h3>
                    <h3>{props.text3}</h3>
                </div>
                <a href={props.url} className={props.btnClass}>{props.buttonText}</a>
            </div>
        </div>
        </>
    )
}

export default Hero;