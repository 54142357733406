import HomeRoute from "./routes/HomeRoute";
import RegistrationRoute from "./routes/RegistrationRoute";
import ContactRoute from "./routes/ContactRoute";
import InvitationalRoute from "./routes/InvitationalRoute";
import { Route, Routes } from "react-router-dom";
import ScheduleRoute from "./routes/ScheduleRoute";
import "./styles.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeRoute/>}/>
        <Route path="/registration" element={<RegistrationRoute/>}/>
        <Route path="/schedule" element={<ScheduleRoute/>}/>
        <Route path="/contact" element={<ContactRoute/>}/>
        <Route path="/invitational" element={<InvitationalRoute/>}/>
      </Routes>
    </div>
  );
}

export default App;
