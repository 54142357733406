import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import HomeImg from "../assets/blueridge_1.jpg"
import Footer from "../components/Footer";
import { Typography } from "@mui/material";
import PrizeGrid from "../components/PrizeGrid"
import "../styles.css"

function HomeRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero"
            heroImg={HomeImg}
            title="Blue Ridge Open"
            prizeFund="$5650 Total Prize Fund*"
            text="September 20 - 22, 2024"
            text2="Washington Dulles Airport Marriott"
            text3="45020 Aviation Dr, Dulles, VA 20166"
            url="/registration"
            btnClass="show"
            buttonText="REGISTER NOW"
        ></Hero>
        <div className="details">
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                * given 80 paid entries
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Sections: Premier (FIDE-Rated - min rating 1800 USCF/FIDE), U1900, U1600, U1300
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                5 Round Swiss; Up to two irrevocable half-point byes allowed. Byes must be committed 1 hr before Round 2.
            </Typography>
            <Typography color="black" variant="h5" sx={{fontFamily: 'Poppins'}}>
            Schedule:
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            3 Day: 9/20 at 7 PM, 9/21 at 10 AM and 4 PM, 9/22 at 9 AM and 3 PM (G/90+30)
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            2 Day: 9/21 at 10 AM and 1 PM (G/60;d5), schedules merge starting round 3
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
            Premier section has only a 3 Day schedule.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Default Forfeit: 1 hour or full time control for rounds less than 60 mins. 
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Hotel Reservations: Chess Rate of $139/night + taxes/fees. Last day to get the chess rate is 8/30/2024.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Must mention 'Atlantic Chess Association' over the phone at 1-800-228-9290 or 703-471-9500.
            </Typography>
            <Typography color="black" variant="h6" sx={{fontFamily: 'Poppins'}}>
                Blitz: Saturday September 21, approximately 8:30PM. Prizes: Open - $125, Amateur/U1600 - $75. 4 Round double swiss, G/5+0.
            </Typography>
            
            <div className="prizeGrid">
                <Typography color="black" variant="h4" sx={{fontFamily: 'Poppins'}}>
                    Prize Structure
                </Typography>
                <PrizeGrid></PrizeGrid>
            </div>
            
        </div>
        <Footer></Footer>
        </>
    )
}

export default HomeRoute;