import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import AboutImg from "../assets/register.jpg"
import EventType from "../components/EventType"
import  Footer from "../components/Footer";
import "../styles.css"

function RegistrationRoute() {
    return (
        <>
        <Navbar></Navbar>
        <Hero
            className="hero-mid"
            heroImg={AboutImg}
            title="Registration"
            btnClass="hide"
        ></Hero>
        <div className="flexbox-container">
            <EventType
                title="Scholastic - 9/21"
                entryFee="Entry Fee: "
                content=
                "$40 by 8/25, $45 by 9/8, $50 on or after 9/9"
                registerLink="https://caissachess.net/online-registration/index/4818"
                advEntriesLink="https://www.caissachess.net/live/4818"
            >
            </EventType>
            <EventType
                title="Open"
                entryFee="Entry Fee: "
                content=
                "$97 by 8/11, $117 by 8/25, $137 by 9/8, $157 on or after 9/9"
                registerLink="https://caissachess.net/online-registration/index/4811"
                advEntriesLink="https://www.caissachess.net/live/4811"
            >
            </EventType>
            <EventType
                title="Blitz - 9/21"
                entryFee="Entry Fee:"
                content=
                "$30 by 9/14, $35 on or after 9/15"
                registerLink="https://caissachess.net/online-registration/index/4819"
                advEntriesLink="https://www.caissachess.net/live/4819"
            >
            </EventType>
        </div>
        <Footer></Footer>
        </>
    )
}

export default RegistrationRoute;